"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _vue = require("vue");

var _vue2 = _interopRequireDefault(_vue);

var _forEach = require("lodash/forEach");

var _forEach2 = _interopRequireDefault(_forEach);

var _validate = require("validate.js");

var _validate2 = _interopRequireDefault(_validate);

var _axios = require("axios");

var _axios2 = _interopRequireDefault(_axios);

var _uploader = require("./uploader.vue");

var _uploader2 = _interopRequireDefault(_uploader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_validate2.default.validators.agreement = function (value) {
    if (!value) {
        return "^Для отправки обращения вы должны принять условия обработки персональных данных";
    }
};
exports.default = _vue2.default.extend({
    name: "FeedbackForm",
    components: {
        "uploader": _uploader2.default
    },
    props: {
        "format": {
            type: String
        }
    },
    created: function created() {
        var _this = this;
        _axios2.default.get("/assets/ajax/feedback/get-data/", { params: { format: this.format } }).then(function (response) {
            if (response.status === 200 && response.data) {
                _this.subjects = response.data;
                _this.message.data.subject = _this.subjects[0]['id'];
            }
        });
    },
    methods: {
        deleteAttachment: function deleteAttachment(index) {
            this.message.data.files.splice(index, 1);
        },
        hasErrors: function hasErrors() {
            var hasErrors = false;
            (0, _forEach2.default)(this.message.errors, function (value) {
                hasErrors = hasErrors || value;
            });
            return hasErrors;
        },
        postMessage: function postMessage() {
            var _this = this;
            (0, _forEach2.default)(this.message.data, function (value, key) {
                _this.test(key);
            });
            if (!this.hasErrors()) {
                this.message.isSending = true;
                var data_1 = new FormData();
                (0, _forEach2.default)(this.message.data, function (value, key) {
                    if (key !== "files") {
                        data_1.append("data[" + key + "]", value);
                    } else {
                        (0, _forEach2.default)(value, function (file) {
                            data_1.append("files[]", file);
                        });
                    }
                });
                _axios2.default.post("/assets/ajax/feedback/post-data/", data_1).then(function (response) {
                    _this.message.isSent = true;
                });
            }
        },
        test: function test(attribute) {
            if (this.message.data[attribute] !== undefined && this.constraints[attribute]) {
                var result = _validate2.default.single(this.message.data[attribute], this.constraints[attribute]);
                if (result && result[0]) {
                    this.message.errors[attribute] = result[0];
                } else {
                    this.message.errors[attribute] = false;
                }
            }
        }
    },
    data: function data() {
        return {
            modal: false,
            constraints: {
                name: { presence: { message: "^Укажите ваше имя" } },
                email: {
                    presence: { message: "^Укажите адрес электронной почты" },
                    email: { message: "^Указанный адрес не является адресом электронной почты" }
                },
                subject: { presence: { message: "^Укажите тему сообщения" } },
                message: { presence: { message: "^Укажите текст сообщения" } },
                agreement: { agreement: true }
            },
            user: {
                isGuest: true
            },
            message: {
                isSent: false,
                isSending: false,
                data: {
                    name: "",
                    email: "",
                    phone: "",
                    subject: 0,
                    message: "",
                    files: [],
                    agreement: true
                },
                errors: {
                    name: false,
                    email: false,
                    phone: false,
                    subject: false,
                    message: false,
                    agreement: false
                }
            },
            subjects: []
        };
    }
});