//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: {
        size: String,
        expanded: Boolean,
        loading: Boolean,
        icon: String,
        iconPack: String,

        // Native options to use in HTML5 validation
        autocomplete: String,
        maxlength: [Number, String],
        value: {
            type: Array,
            default: function _default() {
                return [];
            }
        },
        multiple: Boolean,
        disabled: Boolean,
        dragDrop: Boolean,
        type: {
            type: String,
            default: 'is-primary'
        }
    },
    data: function data() {
        return {
            isValid: true,
            newValue: this.value || [],
            dragDropFocus: false,
            _elementRef: 'input'
        };
    },

    watch: {
        /**
         * When v-model is changed:
         *   1. Set internal value.
         *   2. If it's invalid, validate again.
         */
        value: function value(_value) {
            this.newValue = _value;
            !this.isValid && !this.dragDrop && this.checkHtml5Validity();
        }
    },
    computed: {
        /**
         * Find parent Field, max 3 levels deep.
         */
        parentField: function parentField() {
            // return this.$parent.$data._isField
            //     ? this.$parent
            //     : this.$parent.$data._isAutocomplete && this.$parent.$parent.$data._isField
            //         ? this.$parent.$parent
            //         : this.$parent.$data._isDatepicker && this.$parent.$parent.$parent.$data._isField
            //             ? this.$parent.$parent.$parent
            //             : null

            var parent = this.$parent;
            for (var i = 0; i < 3; i++) {
                if (parent && !parent.$data._isField) {
                    parent = parent.$parent;
                }
            }
            return parent;
        },


        /**
         * Get the type prop from parent if it's a Field.
         */
        statusType: function statusType() {
            if (!this.parentField) return;

            return this.parentField.newType;
        }
    },
    methods: {
        /**
         * Focus method that work dynamically depending on the component.
         */
        focus: function focus() {
            var _this = this;

            if (this.$refs[this.$data._elementRef] === undefined) return;

            if (!this.$data._isSelect && !this.$data._isAutocomplete && !this.$data._isDatepicker) {
                this.$nextTick(function () {
                    return _this.$refs[_this.$data._elementRef].select();
                });
            } else {
                this.$nextTick(function () {
                    return _this.$refs[_this.$data._elementRef].focus();
                });
            }
        },


        /**
         * Check HTML5 validation, set isValid property.
         * If validation fail, send 'is-danger' type,
         * and error message to parent if it's a Field.
         */
        checkHtml5Validity: function checkHtml5Validity() {
            if (this.$refs[this.$data._elementRef] === undefined) return;

            var el = this.$data._isAutocomplete || this.$data._isDatepicker ? this.$refs.input.$refs.input : this.$refs[this.$data._elementRef];

            var type = null;
            var message = null;
            var isValid = true;
            if (!el.checkValidity()) {
                type = 'is-danger';
                message = el.validationMessage;
                isValid = false;
            }
            this.isValid = isValid;

            if (this.parentField) {
                // Set type only if not defined
                if (!this.parentField.type) {
                    this.parentField.newType = type;
                }
                // Set message only if not defined
                if (!this.parentField.message) {
                    this.parentField.newMessage = message;
                }
            }

            return this.isValid;
        },

        /**
         * Listen change event on input type 'file',
         * emit 'input' event and validate
         */
        onFileChange: function onFileChange(event) {
            if (!this.disabled && !this.loading) {
                if (this.dragDrop) {
                    this.updateDragDropFocus(false);
                }
                var value = event.target.files || event.dataTransfer.files;
                if (value && value.length) {
                    if (!this.multiple) {
                        // only one element in case drag drop mode and isn't multiple
                        if (this.dragDrop) {
                            if (value.length === 1) {
                                this.newValue = [];
                            } else {
                                return false;
                            }
                        } else {
                            this.newValue = [];
                        }
                    }
                    for (var i = 0; i < value.length; i++) {
                        this.newValue.push(value[i]);
                    }
                }
                this.$emit('input', this.newValue);
                !this.dragDrop && this.checkHtml5Validity();
            }
        },


        /**
         * Listen drag-drop to update internal variable
         */
        updateDragDropFocus: function updateDragDropFocus(focus) {
            if (!this.disabled && !this.loading) {
                this.dragDropFocus = focus;
            }
        }
    }
};