var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label", attrs: { for: "area" } }, [
        _vm._v("Округ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.area,
                  expression: "form.data.area"
                }
              ],
              attrs: { id: "area" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form.data,
                      "area",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  function($event) {
                    _vm.test("area")
                  }
                ]
              }
            },
            _vm._l(_vm.cl.area, function(option) {
              return _c("option", { domProps: { value: option.id } }, [
                _vm._v(_vm._s(option.title))
              ])
            })
          )
        ])
      ]),
      _vm._v(" "),
      _vm.form.errors.area
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.area))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label", attrs: { for: "address" } }, [
        _vm._v("Адрес")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "control" },
        [
          _c("v-autocomplete", {
            attrs: {
              id: "address",
              items: _vm.cl.address,
              "min-len": 2,
              placeholder: "Начните вводить адрес",
              "get-label": _vm.getAddressLabel,
              "component-item": _vm.itemTemplate,
              disabled: !_vm.form.data.area
            },
            on: {
              "item-selected": function($event) {
                _vm.test("address")
              },
              "update-items": _vm.onInputAddress
            },
            model: {
              value: _vm.form.data.address,
              callback: function($$v) {
                _vm.$set(_vm.form.data, "address", $$v)
              },
              expression: "form.data.address"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.errors.address
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.address))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label", attrs: { for: "flat" } }, [
        _vm._v("Квартира")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.flat,
                  expression: "form.data.flat"
                }
              ],
              attrs: { id: "flat", disabled: !_vm.form.data.address },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form.data,
                      "flat",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  function($event) {
                    _vm.test("flat")
                  }
                ]
              }
            },
            _vm._l(_vm.cl.flat, function(option) {
              return _c("option", { domProps: { value: option.id } }, [
                _vm._v(_vm._s(option.title))
              ])
            })
          )
        ])
      ]),
      _vm._v(" "),
      _vm.form.errors.flat
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.flat))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label", attrs: { for: "square" } }, [
        _vm._v("Площадь")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.square,
                  expression: "form.data.square"
                }
              ],
              attrs: { id: "square", disabled: !_vm.form.data.flat },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form.data,
                      "square",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  function($event) {
                    _vm.test("square")
                  }
                ]
              }
            },
            _vm._l(_vm.cl.square, function(option) {
              return _c("option", { domProps: { value: option.id } }, [
                _vm._v(_vm._s(option.title))
              ])
            })
          )
        ])
      ]),
      _vm._v(" "),
      _vm.form.errors.square
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.square))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("Страховое покрытие за кв. м")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c(
          "div",
          { staticClass: "switch" },
          _vm._l(_vm.cl.type, function(type) {
            return _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.data.type,
                    expression: "form.data.type"
                  }
                ],
                attrs: { type: "radio", disabled: !_vm.form.data.square },
                domProps: {
                  value: type.value,
                  checked: _vm._q(_vm.form.data.type, type.value)
                },
                on: {
                  change: function($event) {
                    _vm.$set(_vm.form.data, "type", type.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(type.label))])
            ])
          })
        )
      ]),
      _vm._v(" "),
      _vm.form.errors.type
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.type))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Оплачивать")]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "control" }, [
          _c(
            "div",
            { staticClass: "switch" },
            _vm._l(_vm.cl.period, function(type) {
              return _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.data.period,
                      expression: "form.data.period"
                    }
                  ],
                  attrs: { type: "radio", disabled: !_vm.form.data.type },
                  domProps: {
                    value: type.value,
                    checked: _vm._q(_vm.form.data.period, type.value)
                  },
                  on: {
                    change: function($event) {
                      _vm.$set(_vm.form.data, "period", type.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(type.label))])
              ])
            })
          )
        ])
      ]),
      _vm._v(" "),
      _vm.form.errors.period
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.form.errors.period))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.result.calculated
      ? _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "notification" }, [
            _vm._v(
              "\n            Стоимость страховки: " +
                _vm._s(_vm.result.payment) +
                " "
            ),
            _c("span", { staticClass: "rouble" }),
            _vm._v(
              " " +
                _vm._s(_vm.form.data.period === 1 ? "в месяц" : "в год") +
                "  "
            ),
            _c("br"),
            _vm._v(
              "\n            Страховое покрытие: " +
                _vm._s(_vm.result.cover) +
                " "
            ),
            _c("span", { staticClass: "rouble" }),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.result.message) } })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-977dd462", { render: render, staticRenderFns: staticRenderFns })
  }
}