var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "su-calculator" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.view(1)
          ? _c("div", [
              _c("div", { staticClass: "su-calculator__intro" }, [
                _c("p", [
                  _vm._v(
                    "\n                    Интерактивный опросник «Определение возможного способа улучшения жилищных условий» позволяет Вам, ответив на ряд вопросов, самостоятельно определить подходящий Вам способ улучшения жилищных условий."
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Точность результатов опроса напрямую зависит от объективности и достоверности предоставляемой информации."
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Полученные с использованием электронного опросника результаты носят сугубо ознакомительный характер, не являются официальным документом\n                   и не могут являться основанием для обеспечения или отказа в обеспечении жилыми помещениями."
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "button is-dark",
                      on: {
                        click: function($event) {
                          _vm.viewId = 2
                        }
                      }
                    },
                    [_vm._v("Начать опрос")]
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(2)
          ? _c("div", [
              _c("div", { staticClass: "su-calculator__items" }, [
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 3
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Я состою на жилищном учете нуждающихся в улучшении жилищных условий"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "help" }, [
                      _c("i", { staticClass: "fa fa-info" }),
                      _vm._v(
                        " Нуждающимися в улучшении жилищных условий являются граждане, вставшие на жилищный учет до 01.03.2005"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 4
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v("Я состою на учете нуждающихся в жилых помещениях")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "help" }, [
                      _c("i", { staticClass: "fa fa-info" }),
                      _vm._v(
                        "\n                        Нуждающимися в жилых помещениях считаются граждане, вставшие на жилищный учет после 01.03.2005 г. и признанные в установленном порядке малоимущими в целях постановки на жилищный учет.\n                    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 5
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Я состою на учете нуждающихся в содействии города Москвы в приобретении жилых помещений"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "help" }, [
                      _c("i", { staticClass: "fa fa-info" }),
                      _vm._v(
                        "\n                        Нуждающимися в жилых помещениях считаются граждане, вставшие на жилищный учет после 01.03.2005 г. и признанные в установленном порядке малоимущими в целях постановки на жилищный учет.\n                    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 6
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v("Я не состою на учете нуждающихся в городе Москве")
                    ])
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(3)
          ? _c("div", [
              _c("h2", [
                _vm._v("Учет нуждающихся в улучшении жилищных условий")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                На учете нуждающихся в улучшении жилищных условий состоят граждане, вставшие на жилищный учет до принятия Жилищного кодекса Российской Федерации (до 01.03.2005 г.). Граждане, состоящие на учете нуждающихся в улучшении жилищных условий, имеют право воспользоваться как безвозмездными, так и возмездными способами улучшения жилищных условий:\n            "
                )
              ]),
              _vm._v(" "),
              _c("h2", [
                _vm._v("Безвозмездные способы улучшения жилищных условий:")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Жилые помещения по договору социального найма предоставляются гражданам, состоящим на жилищном учете, в порядке очередности в бессрочное пользование."
                )
              ]),
              _vm._v(" "),
              _c("h2", [
                _vm._v("Возмездные способы улучшения жилищных условий:")
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "Покупка жилого помещения с использованием социального ипотечного кредитования"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Город Москва предоставляет заявителю возможность приобрести в собственность жилое помещение из жилищного фонда города Москвы по договору купли-продажи с использованием социального ипотечного кредитования."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Вы можете сами выбрать банк, в котором Вы бы хотели оформить ипотечный кредит для выкупа жилого помещения, предоставляемого городом. При этом проверка Вашей платежеспособности и принятие решения о выдаче кредита будут осуществляться непосредственно коммерческим банком, в который Вы обратились."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Выкупная стоимость приобретаемого жилого помещения определяется Департаментом жилищной политики и жилищного фонда города Москвы согласно методике оценки, утвержденной постановлением Правительства города Москвы. При этом система коэффициентов учитывается место расположения жилого здания, количество лет пребывания граждан на жилищном учете (для граждан, состоящих на учете нуждающихся в содействии в приобретении жилых помещений, коэффициент максимальный)."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Для семей, имеющих трех и более несовершеннолетних детей, при подписании договора выкупная стоимость жилого помещения определяется за вычетом 30% из площади жилого помещения."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("Покупка жилого помещения с рассрочкой платежа")
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Город Москва предоставляет возможность приобрести в собственность одно из жилых помещений жилищного фонда города Москвы по договору купли-продажи с рассрочкой платежа."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Жилое помещение приобретается в собственность непосредственно у города Москвы с предоставлением рассрочки на оплату выкупной стоимости до 10 лет."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Выкупная стоимость приобретаемого жилого помещения определяется Департаментом жилищной политики и жилищного фонда города Москвы согласно методике оценки, утвержденной постановлением Правительства города Москвы. При этом система коэффициентов учитывается место расположения жилого здания, количество лет пребывания граждан на жилищном учете (для граждан, состоящих на учете нуждающихся в содействии в приобретении жилых помещений, коэффициент максимальный)."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Первоначальный взнос за приобретаемую квартиру составляет 30% от стоимости жилого помещения, но в зависимости от различных факторов (наличие 2 и более детей, наличие в семье инвалида и т.д.) может колебаться в диапазоне от 10 до 80%. Рассрочка платежа предоставляется сроком до 10 лет, под 10,5% годовых."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Для семей, имеющих трех и более несовершеннолетних детей, при подписании договора выкупная стоимость жилого помещения определяется за вычетом 30% из площади жилого помещения."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Также в период действия договора участникам предоставляется льгота в виде списания части задолженности за приобретенное жилое помещение при рождении (усыновлении) в семье ребенка."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Договором предусматривается возможность досрочного погашения части задолженности по выплате выкупной стоимости либо досрочного выкупа жилого помещения в любой момент после государственной регистрации данного договора."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "Наём жилого помещения в сети бездотационных домов города Москвы"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Получить квартиру в сети бездотационных домов по договору найма могут граждане, состоящие на учете нуждающихся в улучшении жилищных условий и учете нуждающихся в содействии города Москвы в приобретении жилых помещений в рамках городских жилищных программ."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Жилые помещения в сети бездотационных домов предоставляются по договору найма, заключаемому на 5 лет, с возможностью дальнейшего продления. Такие жилые помещения не могут быть приватизированы или выкуплены."
                )
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "Субсидия из бюджета города Москвы для строительства или приобретения жилого помещения"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Данный способ улучшения жилищных условий предполагает безвозмездное предоставление денежных средств, с использованием которых Вы можете приобрести себе наиболее подходящее для Вас жилое помещение, как на первичном, так и на вторичном рынке жилья."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "При использовании субсидии Вы не ограничены наличием свободных жилых помещений в жилищном фонде города Москвы, субсидию возможно реализовать на территории любого субъекта Российской Федерации. Возвращать субсидию из бюджета города Москвы, полученную на покупку или строительство жилого помещения, "
                  ),
                  _c("strong", [_vm._v("не нужно")]),
                  _vm._v(".")
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Размер субсидии определяется исходя из состава семьи, уровня обеспеченности жилой площадью, количества лет пребывания на учете, нормативной стоимости одного квадратного метра жилого помещения. Нормативная стоимость одного квадратного метра ежеквартально определяется Правительством Москвы."
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(4)
          ? _c("div", [
              _c("h2", [_vm._v("Учет нуждающихся в жилых помещениях")]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "На учете нуждающихся в жилых помещениях состоят граждане, вставшие на жилищный учет после принятия Жилищного кодекса Российской Федерации (после 01.03.2005 г.). Все граждане, состоящие на учете нуждающихся в жилых помещениях, признаны в установленном порядке малоимущими, в связи с чем они могут воспользоваться только безвозмездными способами улучшения жилищных условий."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Вместе с тем, многодетным семьям (с 3 и более детьми), состоящим на учете нуждающихся в жилых помещениях предусмотрено предоставлении безвозмездной субсидии из бюджета города Москвы на покупку или строительство жилого помещения."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _c("strong", [
                    _vm._v("Безвозмездные способы улучшения жилищных условий:")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Предоставление жилого помещения по договору социального найма"
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Предоставление жилого помещения по договору безвозмездного пользования"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "График работы и адрес соответствующего Управления Вы можете найти на официальном сайте Департамента жилищной политики и жилищного фонда города Москвы по ссылке: "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://housing.mos.ru/dmghtml/directs/index.htm",
                        target: "_blank",
                        rel: "noopener"
                      }
                    },
                    [_vm._v("http://housing.mos.ru/dmghtml/directs/index.htm")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Также Вы можете подать заявление в электроном виде через Портал государственных услуг города Москвы"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "//pgu.mos.ru",
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "auto",
                        "max-width": "100%",
                        "border-width": "0px",
                        "border-image-width": "initial"
                      },
                      attrs: {
                        src:
                          "http://62.117.112.170:8081/calculator/img/pgumosru.jpg"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Обращаем Ваше внимание, что вся предоставляемая Вами информация подлежит проверке при обращении в Департамент жилищной политики и жилищного фонда города Москвы. Результаты, полученные с использованием электронного опросника Департамента, не могут являться основанием для обеспечения Вас жилым помещением или оказания иной поддержки города Москвы в жилищной сфере."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Жилищные условия граждан, состоящих на учете нуждающихся в жилых помещениях, осуществляются в порядке очередности, исходя из даты постановки на учет."
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(5)
          ? _c("div", [
              _c("h2", [
                _vm._v(
                  "Учет нуждающихся в содействии города Москвы в приобретении жилых помещений."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Категория нуждающихся в содействии города Москвы в приобретении жилых помещений в рамках городских жилищных программ появилась после 01.03.2005 года. Граждане, состоящие на учете нуждающихся в содействии города Москвы в приобретении жилых помещений в рамках городских жилищных программ, не признаются в установленном порядке малоимущими, в связи с чем имеют право только на возмездные способы улучшения жилищных условий."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "рафик работы и адрес соответствующего Управления Вы можете найти на официальном сайте Департамента жилищной политики и жилищного фонда города Москвы по ссылке: "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://housing.mos.ru/dmghtml/directs/index.htm"
                      }
                    },
                    [_vm._v("http://housing.mos.ru/dmghtml/directs/index.htm")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "//pgu.mos.ru",
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "auto",
                        "max-width": "100%",
                        "border-width": "0px",
                        "border-image-width": "initial"
                      },
                      attrs: {
                        src:
                          "http://62.117.112.170:8081/calculator/img/pgumosru.jpg"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Обращаем Ваше внимание, что вся предоставляемая Вами информация подлежит проверке при обращении в Департамент жилищной политики и жилищного фонда города Москвы. Результаты, полученные с использованием электронного опросника Департамента, не могут являться основанием для обеспечения Вас жилым помещением или оказания иной поддержки города Москвы в жилищной сфере."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Жилищные условия граждан, состоящих на учете нуждающихся в содействии города Москвы в приобретении жилых помещений в рамках городских жилищных программ, осуществляются в порядке очередности, исходя из даты постановки на учет."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Гражданам, состоящим на учете нуждающихся в содействии города Москвы в приобретении жилых помещений, возможность улучшения жилищных условий предоставляется после обеспечения граждан, состоящих на учете нуждающихся в жилых помещениях (до 01.03.2005)."
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(6)
          ? _c("div", [
              _c("h2", [
                _vm._v("Являетсь ли Вы гражданином Российской Федерации?")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "su-calculator__items" }, [
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 61
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v("Да, я являюсь гражданином Российской Федерации")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 62
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Нет, я не являюсь гражданином Российской Федерации"
                      )
                    ])
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(61)
          ? _c("div", [
              _c("h2", [
                _vm._v(
                  "Сколько лет, в общей сложности, Вы и члены Вашей семьи проживаете в городе Москве по месту жительства?"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "help" }, [
                _c("p", [
                  _vm._v(
                    "Под сроком проживания в городе Москве подразумевается количество лет, в течение которых члены семьи зарегистрированы в городе Москве по месту жительства. "
                  ),
                  _c("strong", [
                    _vm._v(
                      "Не учитывается время, в течение которого члены семьи зарегистрированы в городе Москве по месту пребывания, а также срок проживания несовершеннолетних детей."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    При ответе на вопрос учитывается срок проживания лиц, зарегистрированных по месту жительства совместно с заявителем (статья 1 закона города Москвы от 14.06.2006 г. № 29 «Об обеспечении права жителей города Москвы на жилые помещения»)."
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Срок проживания в городе Москве определяется индивидуально для каждого из указанных граждан."
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("em", [_vm._v("Например")]),
                  _vm._v(
                    ": семья состоит из 4 человек: муж (проживает в городе Москве 4 года), жена (проживает в городе Москве 10 лет), и родителей супругов (проживают в городе Москве более 10 лет), то при ответе на вопрос считается, что семья не проживает в городе Москве на законных основаниях 10 лет и более, в связи с тем, что супруг проживает в городе Москве всего 4 года.\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "su-calculator__items" }, [
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 612
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Все указанные лица зарегистрированы по месту жительства в городе Москве 10 и более лет"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 613
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Кто-либо из указанных лиц зарегистрирован по месту жительства в городе Москве менее 10 лет"
                      )
                    ])
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(613)
          ? _c("div", [
              _c("h2", [
                _vm._v(
                  "Ухудшали ли Вы в течение последних 5 лет свои жилищные условия?"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "help" }, [
                _c("p", [
                  _vm._v(
                    "Жители города Москвы признаются нуждающимися в жилых помещениях "
                  ),
                  _c("strong", [_vm._v("истечении пяти лет")]),
                  _vm._v(
                    " после совершения ими действий, в результате которых такие граждане могут быть признаны нуждающимися в жилых помещениях ("
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://62.117.112.170:8081/calculator/main.html"
                      }
                    },
                    [
                      _vm._v(
                        "статья 10 закона города Москвы от 14.06.2006 г. № 29 «Об обеспечении права жителей города Москвы на жилые помещения»"
                      )
                    ]
                  ),
                  _vm._v(").\n                ")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "su-calculator__items" }, [
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 6131
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Да, в течение последних 5 лет я или члены моей семьи совершили действия, повлекшие ухудшения жилищных условий"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "su-calculator__item",
                    on: {
                      click: function($event) {
                        _vm.viewId = 6132
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Нет, в течение последних 5 лет действий повлекших ухудшения жилищных условий не совершалось"
                      )
                    ])
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(62)
          ? _c("div", [
              _vm._v(
                "\n            В соответствии с Жилищным кодексом Российской Федерации жилыми государственная поддержка в жилищной сфере оказывается гражданам Российской Федерации.\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(6131)
          ? _c("div", [
              _c("h2", [_vm._v("Ухудшение жилищных условий")]),
              _vm._v(
                "\n            В соответствии с пунктом 4 статьи 7 Закона города Москвы от 14 июня 2006 года № 29 вопрос постановки Вашей семьи на жилищный учет в городе Москве может быть рассмотрен по истечении 5 лет с момента совершения действий, повлекших ухудшение жилищных условий.\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(6132)
          ? _c("div", [
              _c("h2", [
                _vm._v(
                  "Жилищная обеспеченность каждого члена семьи заявителя менее учетной нормы?"
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [
                  _c("small", [
                    _vm._v("четная норма устанавливается в размере "),
                    _c("strong", [_vm._v("10кв. м")]),
                    _vm._v(
                      " площади жилого помещения для отдельных квартир и "
                    ),
                    _c("strong", [_vm._v("15 кв. м ")]),
                    _vm._v(
                      "площади жилого помещения для квартир, жилые помещения в которых предоставлены по решениям уполномоченных органов исполнительной власти города Москвы разным семьям "
                    ),
                    _c("em", [
                      _vm._v(
                        "(статья 9 закона города Москвы от 14.06.2006 г. № 29 «Об обеспечении права жителей города Москвы на жилые помещения»)"
                      )
                    ]),
                    _vm._v(".\n                    ")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Жилищная обеспеченность вычисляется по следующей формуле:"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", [_c("p", [_c("small", [_vm._v("S / Q, где:")])])]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _c("strong", [_vm._v("S")]),
                    _vm._v(
                      " - суммарная площадь всех жилых помещений, частей жилых помещений, в отношении которых кто-либо из членов семьи обладает самостоятельным правом пользования*, либо правом собственности\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _c("strong", [_vm._v("Q")]),
                    _vm._v(
                      " - количество членов семьи заявителя (включая заявителя)"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "\n                        * Не обладают самостоятельным правом пользования жилыми помещениями граждане, занимающие жилые помещения на основании договора поднайма, договора краткосрочного найма, а также граждане, пользующиеся жилыми помещениями в качестве временных жильцов.\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Обратите внимание, что показатель рассчитывается на основе "
                    ),
                    _c("strong", [_vm._v("площади жилого помещения")]),
                    _vm._v(
                      ", которая состоит из суммы площадей всех частей жилого помещения, "
                    ),
                    _c("strong", [
                      _vm._v("площадь помещений вспомогательного использования")
                    ]),
                    _vm._v(
                      "(коридоры, ванные комнаты, кухни и т.д.), балконов и лоджий "
                    ),
                    _c("em", [
                      _vm._v(
                        "(статья 1 закона города Москвы от 14.06.2006 г. № 29)"
                      )
                    ]),
                    _vm._v(".\n                    ")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "При определении жилищной обеспеченности учитывается площадь жилых помещений следующих членов семьи: супруг(супруга) и их несовершеннолетние дети "
                    ),
                    _c("strong", [_vm._v("независимо от места их жительства")]),
                    _vm._v(
                      ", лица, объединенные признаками родства или свойства, совместно проживающие в жилом помещении, а также граждане, проживающие совместно с заявителем, в том числе вселенные им в жилое помещение в качестве членов семьи в установленном порядке либо на основании решения суда.\n                    "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form" }, [
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Суммарная площадь жилых помещений, кв.м.:")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.v61321,
                          expression: "v61321"
                        }
                      ],
                      staticClass: "input",
                      staticStyle: { "max-width": "24rem" },
                      attrs: { type: "text" },
                      domProps: { value: _vm.v61321 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.v61321 = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Количество членов семьи заявителя:")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.v61322,
                          expression: "v61322"
                        }
                      ],
                      staticClass: "input",
                      staticStyle: { "max-width": "24rem" },
                      attrs: { type: "text" },
                      domProps: { value: _vm.v61322 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.v61322 = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.v61323,
                            expression: "v61323"
                          }
                        ],
                        attrs: { type: "radio", name: "question", value: "10" },
                        domProps: { checked: _vm._q(_vm.v61323, "10") },
                        on: {
                          change: function($event) {
                            _vm.v61323 = "10"
                          }
                        }
                      }),
                      _vm._v(" Отдельная квартира")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.v61323,
                            expression: "v61323"
                          }
                        ],
                        attrs: { type: "radio", name: "question", value: "15" },
                        domProps: { checked: _vm._q(_vm.v61323, "15") },
                        on: {
                          change: function($event) {
                            _vm.v61323 = "15"
                          }
                        }
                      }),
                      _vm._v(
                        "\n                        Квартира, жилые помещения в которой предоставлены по решениям уполномоченных органов исполнительной власти города Москвы разным семьям"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.error6132
                  ? _c("div", { staticClass: "field" }, [
                      _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.error6132))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-dark",
                      on: { click: _vm.calc6132 }
                    },
                    [_vm._v("Рассчитать")]
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.view(61321)
          ? _c("div", [
              _c("div", [
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "У Вас есть основания для постановки на жилищный учет в случае если:"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Вы проживаете в жилых помещениях, признанных в установленном порядке непригодными для постоянного проживания и ремонту и реконструкции не подлежащими."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Вы являетесь нанимателем жилого помещения по договорам социального найма, членом семьи нанимателя жилого помещения по договору социального найма или собственником жилого помещений, членом семьи собственника жилого помещения, "
                    ),
                    _c("strong", [
                      _vm._v(
                        "проживающим в квартире, занятой несколькими семьями"
                      )
                    ]),
                    _vm._v(
                      ", если в составе семьи имеется больной, страдающий тяжелой формой хронического заболевания, при которой совместное проживание с ним в одной квартире невозможно, и у Вас не имеется иного жилого помещения, занимаемого по договору социального найма или принадлежащего на праве собственности."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v("Перечень соответствующих заболеваний установлен "),
                    _c("em", [
                      _vm._v(
                        'постановлением Правительства Российской Федерации от 16 июня 2006 года N 378 "Об утверждении перечня тяжелых форм хронических заболеваний, при которых невозможно совместное проживание граждан в одной квартире"'
                      )
                    ]),
                    _vm._v(".")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Вы проживаете в доме, в котором отсутствует хотя бы 1 из видов удобств: электроснабжение, водопровод, канализация, отопление, ванна или душ, газовая или электрическая плита, горячее водоснабжение или газовая колонка."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Вы не являетесь собственником жилого помещения или членом семьи собственника жилого помещения, не обладаете самостоятельным правом пользования жилыми помещениями (за исключением лиц, для которых город Москва является местом пребывания)."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Не обладают самостоятельным правом пользования жилыми помещениями граждане, занимающие жилые помещения на основании договора поднайма, договора краткосрочного найма, а также граждане, пользующиеся жилыми помещениями в качестве временных жильцов ("
                    ),
                    _c("em", [
                      _vm._v(
                        "статья 9 закона города Москвы от 14.06.2006 г. № 29 «Об обеспечении права жителей города Москвы на жилые помещения»"
                      )
                    ]),
                    _vm._v(")")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "Вы утратили жилое помещение в городе Москве и имеете право на предоставление жилого помещения, а возврат ранее занимаемого жилого помещения не представляется возможным (дом снесен, переоборудован, в ранее занимаемые жилые помещения вселены в установленном порядке иные лица), при условии отсутствия у Вас других жилых помещений в отношении которых Вы обладаете правом собственности или самостоятельного пользования и Вы не получали компенсацию в денежной или материальной форме по предыдущему месту жительства."
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0acb74c4", { render: render, staticRenderFns: staticRenderFns })
  }
}