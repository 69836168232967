var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "upload control" }, [
    _c(
      "div",
      {
        staticClass: "upload-draggable",
        class: [
          _vm.type,
          {
            "is-loading": _vm.loading,
            "is-disabled": _vm.disabled,
            "is-hovered": _vm.dragDropFocus
          }
        ],
        on: {
          dragover: function($event) {
            $event.preventDefault()
            _vm.updateDragDropFocus(true)
          },
          dragleave: function($event) {
            $event.preventDefault()
            _vm.updateDragDropFocus(false)
          },
          dragenter: function($event) {
            $event.preventDefault()
            _vm.updateDragDropFocus(true)
          },
          drop: function($event) {
            $event.preventDefault()
            return _vm.onFileChange($event)
          }
        }
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "input",
      _vm._b(
        {
          ref: "input",
          attrs: {
            type: "file",
            multiple: _vm.multiple,
            disabled: _vm.disabled
          },
          on: { change: _vm.onFileChange }
        },
        "input",
        _vm.$attrs,
        false
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-248821ea", { render: render, staticRenderFns: staticRenderFns })
  }
}