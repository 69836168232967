//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _vAutocomplete = require("v-autocomplete");

var _vAutocomplete2 = _interopRequireDefault(_vAutocomplete);

var _vue = require("vue");

var _vue2 = _interopRequireDefault(_vue);

var _calcAddressItem = require("./calc-address-item.vue");

var _calcAddressItem2 = _interopRequireDefault(_calcAddressItem);

var _axios = require("axios");

var _axios2 = _interopRequireDefault(_axios);

var _validate = require("validate.js");

var _validate2 = _interopRequireDefault(_validate);

var _forEach = require("lodash/forEach");

var _forEach2 = _interopRequireDefault(_forEach);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue2.default.use(_vAutocomplete2.default);

exports.default = {
    components: {
        "v-autocomplete": _vAutocomplete2.default
    },
    created: function created() {
        var _this = this;

        _axios2.default.get("/assets/ajax/insurance/calculate/get-area/").then(function (response) {
            if (response.status === 200 && response.data) {
                _this.cl.area = response.data;
            }
        });
        _axios2.default.get("/assets/ajax/insurance/calculate/get-type/").then(function (response) {
            if (response.status === 200 && response.data) {
                _this.cl.type = response.data;
            }
        });
        _axios2.default.get("/assets/ajax/insurance/calculate/get-period/", { params: { type: this.form.data.type } }).then(function (response) {
            if (response.status === 200 && response.data) {
                _this.cl.period = response.data;
            }
        });
    },

    watch: {
        "form.data.address": function formDataAddress(value) {
            var _this2 = this;

            _axios2.default.get("/assets/ajax/insurance/calculate/get-flat/", { params: { building: value.building } }).then(function (response) {
                if (response.status === 200 && response.data) {
                    _this2.cl.flat = response.data;
                }
            });
        },
        "form.data.flat": function formDataFlat(value) {
            var _this3 = this;

            if (this.form.data.address.building) {
                _axios2.default.get("/assets/ajax/insurance/calculate/get-square/", { params: { building: this.form.data.address.building, flat: this.form.data.flat } }).then(function (response) {
                    if (response.status === 200 && response.data) {
                        _this3.cl.square = response.data;
                    }
                });
            }
        },
        "form.data.type": function formDataType(value) {
            var _this4 = this;

            _axios2.default.get("/assets/ajax/insurance/calculate/get-period/", { params: { type: this.form.data.type } }).then(function (response) {
                if (response.status === 200 && response.data) {
                    _this4.cl.period = response.data;
                    _this4.form.data.period = null;

                    if (response.data.period.length === 1) {
                        _this4.form.data.period = response.data[0].value;
                        _this4.calculate();
                    }
                }
            });
        },
        "form.data.period": function formDataPeriod(value) {
            this.calculate();
        }
    },
    computed: {},
    methods: {
        test: function test(attribute) {
            if (this.form.data[attribute] !== undefined && this.constraints[attribute]) {
                var result = _validate2.default.single(this.form.data[attribute], this.constraints[attribute]);
                if (result && result[0]) {
                    this.form.errors[attribute] = result[0];
                } else {
                    this.form.errors[attribute] = false;
                }
            }
        },
        getAddressLabel: function getAddressLabel(item) {
            return item.address;
        },
        onInputAddress: function onInputAddress(value) {
            var _this5 = this;

            _axios2.default.get("/assets/ajax/insurance/calculate/get-address/", { params: { area: this.form.data.area, term: value } }).then(function (response) {
                if (response.status === 200 && response.data) {
                    _this5.cl.address = response.data;
                }
            });
        },
        hasErrors: function hasErrors() {
            var hasErrors = false;
            (0, _forEach2.default)(this.form.errors, function (value) {
                hasErrors = hasErrors || value;
            });

            return hasErrors;
        },
        calculate: function calculate() {
            var _this6 = this;

            (0, _forEach2.default)(this.form.data, function (value, key) {
                _this6.test(key);
            });

            if (!this.hasErrors()) {
                _axios2.default.get("/assets/ajax/insurance/calculate/get-total/", {
                    params: {
                        id: this.form.data.square,
                        type: this.form.data.type,
                        period: this.form.data.period
                    }
                }).then(function (response) {
                    if (response.status === 200 && response.data) {
                        _this6.result.calculated = true;
                        _this6.result.canPay = _this6.period === 1;
                        _this6.result.payment = response.data.payment;
                        _this6.result.cover = response.data.cover;
                        _this6.result.message = response.data.message;
                    }
                });
            }
        }
    },
    data: function data() {
        return {
            constraints: {
                area: { presence: { message: "^Укажите округ" } },
                flat: { presence: { message: "^Укажите квартиру" } },
                square: { presence: { message: "^Укажите площадь" } },
                type: { presence: { message: "^Выберите тип страхового покрытия" } },
                period: { presence: { message: "^Укажите период оплаты" } }
            },
            itemTemplate: _calcAddressItem2.default,
            cl: {
                area: [],
                address: [],
                flat: [],
                square: [],
                type: [],
                period: []
            },
            form: {
                data: {
                    area: null,
                    address: null,
                    flat: null,
                    payer: null,
                    square: null,
                    type: null,
                    period: null
                },
                errors: {
                    area: false,
                    address: false,
                    flat: false,
                    square: false,
                    type: false,
                    period: false
                }
            },
            result: {
                payment: 0,
                cover: 0,
                calculated: false,
                canPay: false,
                message: ""
            }
        };
    }
};