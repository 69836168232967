import Vue from "vue";
import Feedback from "./feedback/form.vue";
import InCalculator from "./insurance/calculator.vue";
import SubsidyCalculator from "./subsidy/calculator.vue";
//обратная связь
var $feedback = $("#feedback-form");
if ($feedback.length) {
    new Vue({
        el: '#feedback-form',
        render: function (h) {
            return h(Feedback, { props: { format: $feedback.attr('data-target') } });
        }
    });
}
//страховой калькулятор
var $incalculator = $("#in-calculator");
if ($incalculator.length) {
    new Vue({
        el: '#in-calculator',
        render: function (h) {
            return h(InCalculator);
        }
    });
}
//калькулятор субсидий
var $scalculator = $("#su-calculator");
if ($scalculator.length) {
    new Vue({
        el: '#su-calculator',
        render: function (h) {
            return h(SubsidyCalculator);
        }
    });
}
(function () {
    //аккордеон
    $(document).on("click", ".accordion__title", function () {
        $(this).parent().find(".accordion__content").toggleClass("accordion__content_active");
    });
    //табы
    $(document).on("click", ".tabs__link", function () {
        $(".tabs__link").removeClass("is-active");
        $(this).addClass("is-active");
        $(".tabs__item").removeClass("is-active");
        $($(this).attr("data-ref")).addClass("is-active");
    });
})();
