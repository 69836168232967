//
//
//
//
//
//

"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: { item: { required: true } }
};