var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feedback-form", class: "feedback-form--" + _vm.format },
    [
      _c("h3", [_vm._v("Обратная связь")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.message.isSent
          ? _c("div", { staticClass: "feedback-form__base" }, [
              _c("div", { staticClass: "field feedback-form__hint" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c("div", { staticClass: "label" }, [
                    _c("span", { staticClass: "required" }, [_vm._v(" ")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "rgb(102, 102, 102)",
                        "font-size": "13px"
                      }
                    },
                    [_vm._v("‐ поля обязательные для заполнения")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "name" } },
                    [
                      _c("span", { staticClass: "required" }, [
                        _vm._v("Ваше имя")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message.data.name,
                          expression: "message.data.name"
                        }
                      ],
                      staticClass: "input",
                      attrs: { id: "name", type: "text" },
                      domProps: { value: _vm.message.data.name },
                      on: {
                        blur: function($event) {
                          _vm.test("name")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.message.data,
                            "name",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.name
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.name))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "email" } },
                    [
                      _c("span", { staticClass: "required" }, [
                        _vm._v("Электронная почта")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message.data.email,
                          expression: "message.data.email"
                        }
                      ],
                      staticClass: "input",
                      attrs: { id: "email", type: "text", placeholder: "" },
                      domProps: { value: _vm.message.data.email },
                      on: {
                        blur: function($event) {
                          _vm.test("email")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.message.data,
                            "email",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.email
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.email))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "phone" } },
                    [_vm._v("Контактный телефон")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message.data.phone,
                          expression: "message.data.phone"
                        }
                      ],
                      staticClass: "input",
                      attrs: { id: "phone", type: "text" },
                      domProps: { value: _vm.message.data.phone },
                      on: {
                        blur: function($event) {
                          _vm.test("phone")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.message.data,
                            "phone",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.phone
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.phone))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "subject" } },
                    [
                      _c("span", { staticClass: "required" }, [
                        _vm._v("Тема обращения")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message.data.subject,
                              expression: "message.data.subject"
                            }
                          ],
                          attrs: { id: "subject" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.message.data,
                                  "subject",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function($event) {
                                _vm.test("subject")
                              }
                            ]
                          }
                        },
                        _vm._l(_vm.subjects, function(option) {
                          return _c(
                            "option",
                            { domProps: { value: option.id } },
                            [_vm._v(_vm._s(option.title))]
                          )
                        })
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.subject
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.subject))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "message" } },
                    [
                      _c("span", { staticClass: "required" }, [
                        _vm._v("Сообщение")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message.data.message,
                          expression: "message.data.message"
                        }
                      ],
                      staticClass: "textarea",
                      attrs: { id: "message" },
                      domProps: { value: _vm.message.data.message },
                      on: {
                        change: function($event) {
                          _vm.test("message")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.message.data,
                            "message",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.message
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.message))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Файлы")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "feedback-form__control" },
                  [
                    _c(
                      "uploader",
                      {
                        attrs: { multiple: "", "drag-drop": "" },
                        model: {
                          value: _vm.message.data.files,
                          callback: function($$v) {
                            _vm.$set(_vm.message.data, "files", $$v)
                          },
                          expression: "message.data.files"
                        }
                      },
                      [
                        _c("div", { staticClass: "feedback-form__uploader" }, [
                          _c("i", { staticClass: "fa fa-upload" }),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticStyle: { padding: "0", margin: "0" } },
                            [_vm._v("Прикрепить файлы к сообщению")]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tags",
                        staticStyle: { padding: "1rem 0" }
                      },
                      _vm._l(_vm.message.data.files, function(file, index) {
                        return _c("span", { key: index, staticClass: "tag" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(file.name) +
                              " "
                          ),
                          _c("button", {
                            staticClass: "delete is-small",
                            on: {
                              click: function($event) {
                                _vm.deleteAttachment(index)
                              }
                            }
                          })
                        ])
                      })
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message.data.agreement,
                            expression: "message.data.agreement"
                          }
                        ],
                        attrs: { type: "checkbox", id: "agreement" },
                        domProps: {
                          checked: Array.isArray(_vm.message.data.agreement)
                            ? _vm._i(_vm.message.data.agreement, null) > -1
                            : _vm.message.data.agreement
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.message.data.agreement,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.message.data,
                                    "agreement",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.message.data,
                                    "agreement",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.message.data, "agreement", $$c)
                            }
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "feedback-form__modal-opener",
                        on: {
                          click: function($event) {
                            _vm.modal = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          "Я соглашаюсь на обработку моих персональных данных"
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.message.errors.agreement
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.message.errors.agreement))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "feedback-form__label" }),
                _vm._v(" "),
                _c("div", { staticClass: "feedback-form__control" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-dark",
                      attrs: { disabled: _vm.message.isSending },
                      on: { click: _vm.postMessage }
                    },
                    [
                      _vm._v("\n                       Отправить "),
                      _vm.message.isSending
                        ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ])
          : _c("div", { staticClass: "feedback-form__success" }, [
              _c("div", { staticClass: "success-mark" }, [
                _c("div", { staticClass: "success-mark__icon" }, [
                  _c("span", { staticClass: "success-mark__line1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "success-mark__line2" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "success-mark__placeholder" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "success-mark__fix" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "success-mark__message",
                    staticStyle: { "font-size": "1rem" }
                  },
                  [
                    _vm._v(
                      "\n                    Ваше обращение отправлено\n                "
                    )
                  ]
                )
              ])
            ])
      ]),
      _vm._v(" "),
      _c("div", { class: { modal: true, "is-active": _vm.modal } }, [
        _c("div", {
          staticClass: "modal-background",
          on: {
            click: function($event) {
              _vm.modal = false
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-card" }, [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [
              _vm._v("Согласие на обработку персональных данных")
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: {
                click: function($event) {
                  _vm.modal = false
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c(
              "button",
              {
                staticClass: "button is-dark",
                on: {
                  click: function($event) {
                    _vm.modal = false
                  }
                }
              },
              [_vm._v("Закрыть")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "modal-card-body", staticStyle: { "font-size": ".9rem" } },
      [
        _vm._v(
          "\n                Я ознакомлен с тем, что в соответствии с требованиями Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных данных»,\n                включая положения пунктов 2-4 части 1 статьи 6 указанного Федерального закона, будет осуществляться обработка предоставляемых мною персональных данных,\n                в том числе фамилии, имени, отчества; даты рождения; адреса проживания; сведений о номере основного документа, удостоверяющего личность,\n                сведений о дате выдачи указанного документа и выдавшем его органе; номерах телефонов; адресах электронной почты (е-mail), а так же иных предоставляемых мною данных. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                Одновременно подтверждаю свое согласие на обработку и осуществление следующих действий с моими персональными данными ГБУ «Имущество Москвы»:\n                сбор, запись, систематизацию, накопление, хранение, извлечение, уточнение, обновление, изменение, использование, обезличивание,\n                распространение (в том числе передачу третьим лицам), блокирование, уничтожение. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                Настоящее согласие не устанавливает предельных сроков обработки персональных данных. "
        ),
        _c("br"),
        _vm._v(
          "\n                Персональные данные предоставляются мною и обрабатываются для обеспечения моей возможности использования информационных систем города Москвы,\n                а также в целях получения мною доступа к различным информационным ресурсам города Москвы, предоставления государственных услуг и исполнения функций\n                органами исполнительной власти города Москвы и подведомственными им организациями, иными организациями. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                Настоящим также подтверждаю свое согласие на получение информации о предоставлении мне государственных услуг. Указанная информация может быть предоставлена мне\n                посредством направления мне сведений по информационно-телекоммуникационной сети «Интернет»\n                на предоставленные мной номер телефона и (или) адрес электронной почты.\n            "
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-dbd7f180", { render: render, staticRenderFns: staticRenderFns })
  }
}