//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    methods: {
        view: function view(id) {
            return this.viewId == id;
        },
        calc6132: function calc6132() {
            if (!this.v61321 || !this.v61322 || !this.v61323) {
                this.error6132 = "Укажите все необходимые параметры для расчета";
            } else {
                var r = this.v61321 / this.v61322;
                this.r6132 = r > this.v61323;
                this.viewId = 61321;
            }
        }
    },
    data: function data() {
        return {
            viewId: 1,
            selection: null,
            error6132: false,
            v61321: null,
            v61322: null,
            v61323: null,
            r6132: null
        };
    }
};